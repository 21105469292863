<template>
  <sidebar-content
    submit-label="Enviar"
    :open="openModal"
    title="Criar Mensagem"
    @submit="submit"
    @close="closeModal">
    <v-container
      grid-list-lg
      class="my-0 pb-0">
      <v-form
        ref="form"
        v-model="formValid"
        lazy-validation
        style="width: 100%;">
        <v-layout
          row
          wrap>
          <v-flex
            xs12
            sm12
            class="pt-0">
            <select-accounts
              data-cy="messageRecipients"
              label="Enviar para"
              groups="administrator,negotiator"
              @change="getPeoples" />
          </v-flex>
          <v-flex
            xs12
            sm12>
            <v-text-field
              v-model="newthread.subject"
              label="Assunto"
              counter="50"
              data-cy="messageSubject"
              :rules="maxLength50"
              required />
          </v-flex>
          <v-flex
            xs12
            sm12>
            <wysiwyg
              v-model="newthread.message.content"
              placeholder="Envie sua mensagem..." />
          </v-flex>
        </v-layout>
      </v-form>
    </v-container>
  </sidebar-content>
</template>

<script>
  import SidebarContent from '@/components/ui/SidebarContent'
  import SelectAccounts from '@/components/ui/SelectAccounts'
  import validate from '@/mixins/validate'
  import masks from '@/mixins/masks'

  export default {
    components: { SidebarContent, SelectAccounts },
    mixins: [ validate, masks ],
    data () {
      return {
        formValid: true,
        openModal: false,
        newthread: {
          participants: [],
          subject: '',
          message: {
            content: ''
          }
        },
        loading: 0
      }
    },
    mounted () {
      this.showModal()
    },
    beforeDestroy () {
      this.closeModal()
    },
    methods: {
      showModal () {
        this.openModal = true
      },
      closeModal () {
        this.openModal = false
        this.$nextTick(() => {
          this.$router.push({ name: 'Messages' })
        })
      },
      getPeoples (peoples) {
        this.newthread.participants = peoples
      },
      submit () {
        if (this.$refs.form.validate()) {
          let thread = Object.assign({}, this.newthread)
          // TODO criar mensagem
        }
      },
      _successCreated () {
        this.$root.$emit('snackbar', {show: true, text: 'Mensagem enviada com sucesso'})
        this.$router.push({ name: 'Messages' })
      },
      _failure () {
        this.$root.$emit('snackbar', {show: true, time: 10000, color: 'error', text: 'Houve um problema, tente novamente'})
      }
    }
  }
</script>

<style lang="sass">
  .editr
    .editr--toolbar
      display: none

    .editr--content
      font-size: 13px
      padding: 12px 15px
      min-height: auto

      p
        margin: 0
        padding: 0

</style>