<template>
  <v-container
    fluid
    class="px-0">
    <v-form
      ref="formPersonal"
      v-model="valid"
      lazy-validation>
      <v-layout
        row
        wrap>
        <v-flex
          xs12
          sm12
          md12>
          <v-autocomplete
            v-model="selected"
            item-text="name"
            item-value="id"
            :label="label"
            :items="accounts"
            :menu-props="{contentClass: dataCy}"
            :data-cy="dataCy"
            :rules="multipleRules"
            multiple
            chips
            @change="change">
            <template v-slot:selection="data">
              <v-chip
                :key="JSON.stringify(data.item)"
                class="chip--select-multi"
                :value="data.selected"
                close
                @input="data.parent.selectItem(data.item)"
                @click:close="deleteAccount(data.item)">
                <v-avatar>
                  <user-avatar
                    :email="data.item.email"
                    :image="data.item.photoURL"
                    :alt="data.item.name" />
                </v-avatar>
                {{ data.item.name }}
              </v-chip>
            </template>
            <template v-slot:item="data">
              <template v-if="typeof data.item !== 'object'">
                <v-list-item-content v-text="data.item" />
              </template>
              <template v-else>
                <v-list-item-avatar color="grey lighten-2">
                  <user-avatar
                    :email="data.item.email"
                    :image="data.item.photoURL"
                    :alt="data.item.name" />
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title v-html="data.item.name" />
                  <v-list-item-subtitle
                    v-for="group in data.item.groups"
                    :key="group.id"
                    v-html="group.name" />
                </v-list-item-content>
              </template>
            </template>
          </v-autocomplete>
        </v-flex>
      </v-layout>
    </v-form>
  </v-container>
</template>

<script>
  import validate from '@/mixins/validate'
  import UserAvatar from '@/components/ui/UserAvatar'

  export default {
    components: { UserAvatar },
    mixins: [ validate ],
    props: {
      label: {
        type: String,
        required: true
      },
      dataCy: {
        type: String,
        default: ''
      },
      groups: {
        type: String,
        required: true
      }
    },
    data: () => ({
      valid: true,
      selected: [],
      loading: 0,
      accounts: []
    }),
    // TODO Listar usuários
    methods: {
      change (val) {
        this.$emit('change', val)
      },
      deleteAccount (account) {
        this.selected = this.selected.filter(id => account.id !== id)
      }
    }
  }
</script>
